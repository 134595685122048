import {Button, Card, Col, Container, Grid, Navbar, Spacer, Text} from "@nextui-org/react";
import {CoverageField} from "./CoverageField";
import {Civico, Comune, Strada} from "./api/egon";
import {CoverageResult} from "./CoverageResult";
import * as React from "react";
import {Coverage} from "./Coverage";

export const Page = () => {
    return <>
        <Navbar maxWidth={"fluid"} variant="sticky">
            <Navbar.Brand>
                <Text b color="inherit">
                    SPADHAUSEN
                </Text>
            </Navbar.Brand>
            <Navbar.Content hideIn="xs">
                <Navbar.Link href="#">Verifica la copertura</Navbar.Link>
                <Navbar.Link isActive href="#">Perché sceglierci</Navbar.Link>
            </Navbar.Content>
            <Navbar.Content>
                <Navbar.Link href="tel:+3905442330899">0544 2330899</Navbar.Link>
            </Navbar.Content>
        </Navbar>
        <Grid.Container css={{minHeight: "35vh"}} alignItems={"center"}>
            <Col>
                <Text h1 size={60} css={{textGradient: "45deg, $blue600 -20%, $pink600 50%", lineHeight: "65px"}}
                      weight="bold">
                    Preferiamo essere chiari,
                </Text>
                <Text h2 css={{textGradient: "45deg, $yellow600 -20%, $red600 100%", lineHeight: "40px"}}
                      weight="bold">
                    perché a non esserlo c'è già abbastanza competizione.
                </Text>
            </Col>
        </Grid.Container>
        <div style={{textAlign: "center", marginTop: "50px"}}>
            <Text h1>Perché sceglierci?</Text>
            <Grid.Container justify={"space-around"}>
                <Card css={{p: "$6", mw: "400px"}}>
                    <Card.Header>
                        1.
                        <Grid.Container css={{pl: "$6"}}>
                            <Grid xs={12}>
                                <Text h3 css={{lineHeight: "$xs"}}>
                                    Nessun costo nascosto
                                </Text>
                            </Grid>
                        </Grid.Container>
                    </Card.Header>
                    <Card.Body css={{py: "$2"}}>
                        <Text>
                            Ci piace essere chiari con il cliente, sin dall'inizio.
                            <br/>Andrai a pagare solo ciò che viene riportato sull'abbonamento che sceglierai,
                            né un centesimo in più, né uno in meno.
                        </Text>
                    </Card.Body>
                </Card>
                <Card css={{p: "$6", mw: "400px"}}>
                    <Card.Header>
                        2.
                        <Grid.Container css={{pl: "$6"}}>
                            <Grid xs={12}>
                                <Text h3 css={{lineHeight: "$xs"}}>
                                    Supporto in qualsiasi momento
                                </Text>
                            </Grid>
                        </Grid.Container>
                    </Card.Header>
                    <Card.Body css={{py: "$2"}}>
                        <Text>
                            Noi di Spadhausen ci impegnamo a fornire il miglior supporto durante tutte le fasi
                            dell'attivazione.
                        </Text>
                    </Card.Body>
                </Card>
                <Card css={{p: "$6", mw: "400px"}}>
                    <Card.Header>
                        3.
                        <Grid.Container css={{pl: "$6"}}>
                            <Grid xs={12}>
                                <Text h3 css={{lineHeight: "$xs"}}>
                                    Siamo belli
                                </Text>
                            </Grid>
                        </Grid.Container>
                    </Card.Header>
                    <Card.Body css={{py: "$2"}}>
                        <Text>
                            T'al deg me.
                        </Text>
                    </Card.Body>
                </Card>
            </Grid.Container>
        </div>

        <Coverage/>

        <footer style={{marginTop: "100px", padding: "50px"}}>
            <Col>
                <Text h3 b>Spadhausen SRL Unipersonale</Text>
                <Text h5>Via E. Chiesa, 6 - Mezzano (RA)</Text>
                <Text h5><a href="tel:05442330899">0544 2330899</a></Text>
            </Col>
        </footer>
    </>
}