import * as React from "react";
import {Modal, Button, Text} from "@nextui-org/react";
import {CoverageResultSpeed} from "./CoverageResultSpeed";

export class CoverageResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offers: this.props.offers,
            isCovered: this.props.offers.length > 0
        }
    }

    render() {
        if (this.state.isCovered) {
            return <Modal
                closeButton
                scroll
                blur
                aria-labelledby="modal-title"
                width="600px"
                open={this.props.visible}
                onClose={() => this.props.setVisible(false)}
            >
                <Modal.Header>
                    <Text h2 b id="modal-title">Sei coperto!</Text>
                </Modal.Header>
                <Modal.Body>
                    {this.props.offers != null ? this.props.offers.map((item) => {
                        return <CoverageResultSpeed
                            ctype={"fibra"} cname={item.type}
                            slogan={item.slogan}
                            details={item.details}
                            speeds={item.speeds}
                        />
                    }) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button css={{width: "100%"}} color="primary" onPress={() => {
                        this.props.setUserInfoVisible(true);
                    }}>
                        Avanti
                    </Button>
                </Modal.Footer>
            </Modal>;
        }

        return <Modal
            closeButton
            scroll
            blur
            aria-labelledby="modal-title"
            width="600px"
            open={this.props.visible}
            onClose={() => this.props.setVisible(false)}
        >
            <Modal.Header>
                <Text h2 b id="modal-title">Non sei coperto :(</Text>
            </Modal.Header>
            <Modal.Body>
                <Text h4>Attualmente non è disponibile alcuna copertura per la tua abitazione,
                ad ogni modo puoi lasciarci i tuoi dati per ricontattarti non appena sarà disponibile!</Text>
            </Modal.Body>
            <Modal.Footer>
                <Button css={{width: "100%"}} color="primary" onPress={() => {}}>
                    Avanti
                </Button>
            </Modal.Footer>
        </Modal>
    }
}