import * as React from "react";
import {Modal, Button, Text, Col, Input, Checkbox} from "@nextui-org/react";
import {CoverageResultSpeed} from "./CoverageResultSpeed";
import {contactInfo} from "./api/contact";

export class CoverageResultUserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            surname: "",
            phone: "",
            email: "",
            confirmVisible: false,
        }

        this.setName = this.setName.bind(this);
        this.setSurname = this.setSurname.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setName(e) {
        this.setState({name: e.target.value})
    }

    setSurname(e) {
        this.setState({surname: e.target.value})
    }

    setPhone(e) {
        this.setState({phone: e.target.value})
    }

    setEmail(e) {
        this.setState({email: e.target.value})
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (await contactInfo(this.props.address, this.state) === 200) {
            this.setState({confirmVisible: true})
        }
    }

    render() {
        return <>
            <Modal
                closeButton
                scroll
                blur
                aria-labelledby="modal-title"
                width="600px"
                open={this.state.confirmVisible}
                onClose={() => window.location.reload()}
            ><Modal.Header>
                <Col>
                    <Text h2 b id="modal-title">Richiesta registrata!</Text>
                    <Text>Ti ricontatteremo il prima possibile!</Text>
                </Col>
            </Modal.Header></Modal>
            <Modal
                closeButton
                scroll
                blur
                aria-labelledby="modal-title"
                width="600px"
                open={this.props.visible}
                onClose={() => this.props.setVisible(false)}
            >
                <Modal.Header>
                    <Col>
                        <Text h2 b id="modal-title">Sei coperto!</Text>
                        <Text>Vorremmo parlare con te per spiegarti al meglio come funziona la nostra azienda e tutti i
                            dettagli dell'offerta che potrai sottoscrivere.</Text>
                    </Col>
                </Modal.Header>
                <form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Input label="Nome" minLength={3} onChange={this.setName}/>
                        <Input label="Cognome" minLength={3} onChange={this.setSurname}/>
                        <Input label="Numero di telefono" minLength={8} onChange={this.setPhone}/>
                        <Input label="Email" minLength={5} onChange={this.setEmail}/>
                        <Checkbox size={"sm"}>
                            Acconsento al trattamento dei miei dati secondo la privacy policy
                        </Checkbox>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" type="submit">
                            Richiedi ricontatto
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal></>;
    }
}