import {Checkbox, Col, Grid, Text} from "@nextui-org/react";
import * as React from "react";

export class CoverageResultSpeed extends React.Component {
    icon = "";
    latestCheckbox = "";

    constructor(props) {
        super(props);

        this.icon = 'https://www.spadhausen.com/media/bollino-' + this.props.ctype + '.png'
        this.state = {
            checkboxValue: [this.props.speeds[0].download.toString() + this.props.speeds[0].upload],
            price: this.props.speeds[0].price,
        }
        this.handleSingleCheckbox = this.handleSingleCheckbox.bind(this)
        this.latestCheckbox = this.props.speeds[0].download.toString() + this.props.speeds[0].upload
    }

    handleSingleCheckbox(e) {
        if (e.length === 0) return;
        for (let i = 0; i < e.length; i++) {
            if (e[i] !== this.latestCheckbox) {
                this.latestCheckbox = e[i]
                let price = ""
                for (let j = 0; j < this.props.speeds.length; j++) {
                    if (this.props.speeds[j].download.toString() + this.props.speeds[j].upload == e[i]) {
                        price = this.props.speeds[j].price;
                    }
                }
                this.setState({checkboxValue: [e[i]], price: price})
            }
        }
    }

    render() {
        return <Grid.Container>
            <Grid xs={12} sm={3} justify={"center"} alignItems={"center"}>
                <div style={{textAlign: "center"}}>
                    <img src={this.icon} alt="Bollino"/>
                    <Text h4>{this.props.cname}</Text>
                </div>
            </Grid>
            <Grid xs={12} sm={9}>
                <Col>
                    <Text>{this.props.slogan}</Text>
                    {this.props.details.map((item) => <Text key={item}>{item}</Text>)}
                    <Checkbox.Group onChange={this.handleSingleCheckbox} value={this.state.checkboxValue}
                                    label="Seleziona la velocità (mbit)"
                                    orientation="horizontal"
                                    color="primary">
                        {this.props.speeds.map((speed) => <Checkbox
                            key={speed.download.toString() + speed.upload}
                            value={speed.download.toString() + speed.upload}>
                            {speed.download}/{speed.upload}
                        </Checkbox>)}
                    </Checkbox.Group>
                    <Text h3 color="success" css={{textAlign: "right"}}>{this.state.price}€/mese</Text>
                </Col>
            </Grid>
        </Grid.Container>;
    }
}