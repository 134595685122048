import React from 'react';
import ReactDOM from 'react-dom/client';
import {createTheme, NextUIProvider} from "@nextui-org/react"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Page} from "./Page";

const darkTheme = createTheme({
    type: 'light',
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <NextUIProvider theme={darkTheme}>
          <BrowserRouter>
              <Routes>
                  <Route path="/">
                      <Route index element={<Page/>}/>
                  </Route>
              </Routes>
          </BrowserRouter>
      </NextUIProvider>
  </React.StrictMode>
);

