import * as React from "react";
import {Button, Col, Input} from "@nextui-org/react";
import debounce from 'lodash.debounce';

export class CoverageField extends React.Component {
    relativeEgon;
    search;

    constructor(props) {
        super(props);
        this.state = {
            helper: false, value: "", status: "default", minLength: this.props.minLength, options: [],
        }

        this.relativeEgon = this.props.relativeEgon;
        this.search = this.props.search;
        this.returnValue = this.returnValue.bind(this)
        this.handleInput = this.handleInput.bind(this)
    }

    returnValue(item) {
        this.props.setResult(item);
    }

    async handleInput(e) {
        const text = e.target.value;
        this.setState({
            value: text,
            helper: text.length >= this.state.minLength,
            status: "invalid",
            options: await this.search(this.relativeEgon, text)
        });
        this.returnValue("");
    }

    debouncedHandleInput = debounce((e) => this.handleInput(e), 500)

    input = null;

    render() {
        return <>
            <Input size="lg" fullWidth placeholder={this.props.name} label={this.props.name}
                   onChange={this.debouncedHandleInput}
                   ref={i => this.input = i} autoFocus={true}/>
            {this.state.helper ? <Col css={{display: "grid", gridRowGap: "5px", marginTop: "5px"}}>
                {this.state.options.map((item) => <Button flat onPress={() => {
                        this.input.value = item.name
                        this.setState({value: item.name, status: "success", helper: false});
                        this.returnValue(item);
                    }}>{item.name}</Button>
                )}
            </Col> : null}
        </>;
    }
}