import * as React from "react";
import {Text, Button, Container, Spacer} from "@nextui-org/react";
import {CoverageField} from "./CoverageField";
import {CoverageResult} from "./CoverageResult";
import {Civico, Strada, Comune} from "./api/egon";
import {Offers} from "./api/offers";
import {CoverageResultUserInfo} from "./CoverageResultUserInfo";


export class Coverage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "", address: "", civic: "", fraction: "", resultVisible: false, userInfoVisible: false,
            referral: new URLSearchParams(document.location.search).get("referral"),
        }

        this.setCity = this.setCity.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.setCivic = this.setCivic.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setUserInfoVisible = this.setUserInfoVisible.bind(this);
    }

    setCity(item) {
        this.setState({city: item.name, city_egon: item.egon})
    }

    setAddress(item) {
        this.setState({address: item.name, address_egon: item.egon, fraction: item.fraction})
    }

    setCivic(item) {
        this.setState({civic: item.name})
    }

    isComplete() {
        return this.state.address !== "" && this.state.city !== "" && this.state.civic !== ""
    }

    setResultVisible(value) {
        this.setState({resultVisible: value})
    }

    setUserInfoVisible(value) {
        this.setState({userInfoVisible: value})
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isComplete() === false) {
            return;
        }

        this.setState({
            offers: await Offers(this.state.city, this.state.fraction, this.state.address + ", " + this.state.civic),
            resultVisible: true,
        })
    }

    render() {
        return <>
            <CoverageResultUserInfo visible={this.state.userInfoVisible} setVisible={this.setUserInfoVisible}
                                    address={{
                                        city: this.state.city,
                                        fraction: this.state.fraction,
                                        address: this.state.address + ", " + this.state.civic
                                    }}/>
            {this.state.resultVisible ?
                <CoverageResult visible={this.state.resultVisible} setVisible={this.setResultVisible}
                                offers={this.state.offers} setUserInfoVisible={this.setUserInfoVisible}/> : null}
            <Container css={{maxWidth: "800px"}}>
                <Text h1>Verifica la Copertura</Text>
                <Spacer y={.5}/>
                <form onSubmit={this.handleSubmit}>
                    <CoverageField name="Comune" search={Comune} setResult={this.setCity} minLength={3}/>
                    {this.state.city != null && this.state.city.length >= 3 ?
                        <CoverageField name="Indirizzo" search={Strada} relativeEgon={this.state.city_egon}
                                       setResult={this.setAddress}
                                       minLength={3}/> : null}
                    {this.state.city != null && this.state.address != null && this.state.address.length >= 3 && this.state.city.length >= 3 ?
                        <CoverageField name="Civico" search={Civico} relativeEgon={this.state.address_egon}
                                       setResult={this.setCivic} minLength={1}/> : null}

                    <Button
                        bordered={this.isComplete() === false}
                        color={this.isComplete() ? "primary" : "gray"}
                        flat={this.isComplete()}
                        css={{width: "100%", marginTop: "20px"}}
                        type="submit">Controlla la copertura</Button>
                </form>
            </Container>
        </>;
    }
}