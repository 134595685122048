export const Comune = async (egon, q) => {
    let data = await fetch("https://api-egon.spadhausen.cloud/search/city?" + new URLSearchParams({q: q,}))
    return data.json();
}

export const Strada = async (egon, q) => {
    let data = await fetch("https://api-egon.spadhausen.cloud/search/street?" + new URLSearchParams({q: q, egon_id: egon}))
    let result = data.json()
    return result;
}

export const Civico = async (egon, q) => {
    let data = await fetch("https://api-egon.spadhausen.cloud/search/civic?" + new URLSearchParams({q: q, egon_id: egon}))
    let result = data.json()
    return result;
}